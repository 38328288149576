import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import "./Navigation.css";
import INavigationProps from '../Interfaces/INavigationProps';
import { getByTitle } from '@testing-library/react';


const Navigation = forwardRef((props: INavigationProps, ref) => {

    const reactStringReplace = require('react-string-replace');
    const [openSearch, setOpenSearch] = useState(false);
    let [isActiveIndex, setIsActiveIndex] = useState(props.indexofTeam);
    let [searchIconColor, setSearchIconColor] = useState("#333333");
    const [previousElementId, setPerviousElementId] = useState("");
    useEffect(() => {

        let element = document.getElementById(reactStringReplace(props.teams[0]?.name, " ", () => "").join(''))
        if (element != null) {
            element.style.visibility = 'visible'
        }
        setPerviousElementId(reactStringReplace(props.teams[0]?.name, " ", () => "").join(''))
    }, [])
        ;
    return (<>
        <div id="wrapper-navigation">
            <div id='navigation' className='wrapper-navigation'>
                <div className='logo'>
                    <a className='link' href="#MainPage">
                        <div className='word-logo' onClick={() => { props.MainPageChange('EmptyCall') }}>
                            <img style={{ marginLeft: "110px", width: "180px" }} src={require('../img/AbonaLogo.svg').default} alt="Logo" />
                        </div>
                    </a>
                </div>
                <nav style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "0px", marginLeft: "-130px" }}>
                    {props.teams.map((item, index) => {
                        let id_team = "#" + reactStringReplace(item.name, " ", () => "").join('');
                        return (<a href={"#" + item.name}
                            className={props.ItemName != null ? props.indexofTeam == index ? 'active' : 'link' : 'link'}
                            id={item.name}
                            onClick={() => {
                                props.getTitle(item.name)
                                let items = document.querySelectorAll("a");
                                items.forEach(i => {
                                    if (i != null) {
                                        if (i.className == 'active') {
                                            i.className = 'link'
                                        }
                                    }
                                })
                                props.changeIndex(index);
                                setIsActiveIndex(index);
                                if(props.showSearchButtonDiv == true)
                                {
                                    props.showSearchButton();
                                    setSearchIconColor("#333333")
                                }
                                if(props.hideSearchElements == true) {
                                    props.hideSearchElementsFunc()
                                }
                            }}>{item.name}</a>)
                        // return (<a href={id_team} className={isActiveIndex==index?'active':''} onClick={()=>{setTimeout(function(){window.scrollBy({top: -100,behavior: 'smooth'});},100);setIsActiveIndex(index);}}>{item.name}</a>
                        // );
                    })}
                </nav>
                {/* <a className='link' href="#Search"> */}
                    <div className={'search-icon'} onClick={() => {
                        props.showSearchButton();
                        if(props.hideSearchElements == true) {
                            props.hideSearchElementsFunc();
                        }
                        if(props.showSearchButtonDiv == false) {
                            setSearchIconColor("#E30613")
                        }
                        else {
                            setSearchIconColor("#333333")
                        }
                        // props.changeIndex(60);
                        // setIsActiveIndex(60);
                        let element = document.getElementById('searchBar');
                        if (element) {
                            if (element.style.visibility == "hidden") {
                                element.style.visibility = "visible"
                                setOpenSearch(true);
                            }
                            else {
                                element.style.visibility = "hidden"
                                setOpenSearch(false);
                            }
                        }
                        setTimeout(() => {
                            function func() {
                                // let height:number;
                                //     height = props.openRef.current.getBoundingClientRect().top;

                                // let topOffset = document.getElementById('navigation')!.offsetHeight;
                                //let offsetPosition = height - topOffset;
                                // window.scrollTo(0, 200)
                                // window.scrollBy({
                                //     top: 100,
                                //     behavior: 'smooth'
                                // });
                            }
                            func()
                        }, 100);
                    }}>
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: "-17px", marginTop: "36px"}}>
                            <path className='SvgSearch' d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.132 12.867 2 9 2C5.132 2 2 5.132 2 9C2 12.867 5.132 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875Z" fill={searchIconColor} />
                        </svg>
                    </div>
                {/* </a> */}
            </div>
        </div>
    </>)
})

export default Navigation;
function componentDidMount() {
    throw new Error('Function not implemented.');
}


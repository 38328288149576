import React, { useState, Component } from "react";
import './AllServices.css';
import { ReactComponent as FullLittleCircle } from '../img/FullLittleCircle.svg';
import Team from "../Team/Team";
import IAllServices from "../Interfaces/IAllServices"
import { AnyTxtRecord } from "dns";
import ITeam from "../Interfaces/ITeam";
import '../SearchBar/SearchBar.css'
import "../Navigation/Navigation.css";
import { ReactComponent as SearchIcon } from "../img/VectorSearchIcon.svg";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'
import TextField from "@mui/material/TextField";
import { useForm, Form } from "./useForm";
import MuiPhoneNumber from 'material-ui-phone-number';
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import ReactDOM from "react-dom";
import { ClassNames } from "@emotion/react";
import ReactPhoneInput from "react-phone-input-2";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReactTelInput, { ReactTelephoneInput } from 'react-telephone-input'
import { margin, width } from "@mui/system";
//import 'react-telephone-input/css/default.css'
//import { createRoot } from "react-dom/client";

interface IAllServicesState {
    sub_teamItem: any[];
    teams: ITeam[];
    options: any[];
    valueText: string;
    searchOptions: any[];
    isOptionOpen: boolean;
    sub_team: any[];
    allTeamName: any[];
    selectedPositions: any[];
    isFormOpen: boolean;
    errors: any
    contactRequest: any;
    fromVisible: boolean;
    IsSuccesses: boolean;
    FooterVisible: boolean;
    disabledfooterpositionlength: number
    ChekSendButton: boolean;
    closeIcon: boolean;
    ResultVisible: boolean;
    ShowHide: boolean;
    DiscardChanges: boolean;
    VariableToPassStateUpdate: boolean;
    LastCloseButton: boolean;
    OpenAfterSearch: boolean

}
export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}
const BootstrapDialogTitle = (props: DialogTitleProps) => {
    //console.log("props",props)
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
let OpenAfterSearch = false
let borderproperties = '3px solid #e4e4e4';
let borderSight = '1px solid #e4e4e4'
const array = ["1", "2", "3", "4", "5", "6", "7"];
class AllServices extends Component<IAllServices, IAllServicesState> {
    constructor(props: IAllServices) {
        super(props);
        this.state = {
            teams: ((props.teams == undefined) ? [] : props.teams),
            options: [],
            sub_teamItem: [],
            valueText: "",
            searchOptions: [],
            isOptionOpen: false,
            sub_team: [],
            allTeamName: [],
            selectedPositions: [],
            isFormOpen: false,
            errors: {
                fullName: "",
                mobile: "",
                email: ""
            },
            contactRequest: {
                fullName: "",
                mobile: "",
                email: ""
            },
            fromVisible: false,
            IsSuccesses: false,
            FooterVisible: true,
            disabledfooterpositionlength: 0,
            ChekSendButton: true,
            closeIcon: false,
            ResultVisible: false,
            ShowHide: false,
            DiscardChanges: false,
            VariableToPassStateUpdate: false,
            LastCloseButton: false,
            OpenAfterSearch: false
        };

    }

    validate = (fieldValues = this.state.contactRequest) => {
        //console.log("this.state.contactRequest",this.state.contactRequest)
        let temp = this.state.errors
        if ('fullName' in fieldValues)
            temp.fullName = fieldValues.fullName ? "" : "This field is required."
        if ('email' in fieldValues) {
            if (fieldValues.email != "") {
                if ((/$^|.+@.+..+/).test(fieldValues.email)) {
                    temp.email = ""
                }
            }
            else {
                temp.email = "Email is not valid"
            }
        }
        if ('mobile' in fieldValues)
            temp.mobile = fieldValues.mobile.length > 11 ? "" : "Minimum 10 numbers required."
        this.setState({ errors: temp })
        if (fieldValues.fullName.length > 0 && fieldValues.mobile.length > 11 && fieldValues.email.includes("@")) {
            this.setState({ ChekSendButton: false })
            borderproperties = '2px solid blue'
        }
        else {
            this.setState({ ChekSendButton: true })
        }
        if (fieldValues.mobile.length < 11 || fieldValues.fullName.length == 0 || fieldValues.email.includes("@")) {
            borderproperties = '1px solid red';
            borderSight = '1px solid red'
            var target = document.getElementsByClassName('form-control')[1];
            //target.addEventListener("click", this.mOver, false);
            // target.addEventListener("clickout", this.mOut, false);
        }
        if (fieldValues.mobile.length > 11) {
            borderproperties = '1px solid #1976d2';
            borderSight = '1px solid #1976d2'
        }
        if (fieldValues == this.state.contactRequest)
            return Object.values(temp).every(x => x == "")
    }
    // mOver = () => {
    //     document.getElementsByClassName('form-control')[1].setAttribute("style", "height: 40px; width: 275px; border:2px solid red;")
    // }

    // mOut() {
    //     document.getElementsByClassName('form-control')[1].setAttribute("style", "height: 40px; width: 275px; border:1px solid red;")
    // }
    getSelectedPositions = (_selectedPositions: any[], isAllChecked: boolean) => {
        let allSelectedPosition = [...this.state.selectedPositions];
        allSelectedPosition = JSON.parse(JSON.stringify(allSelectedPosition))
        if (allSelectedPosition.length == 0) {
            allSelectedPosition.push(_selectedPositions[0])
        }
        else {
            allSelectedPosition.forEach(i => {
                if (i.name == _selectedPositions[0].name) {
                    i.subTeam.forEach((st: any) => {
                        if (st.name == _selectedPositions[0].subTeam[0].name) {
                            st.services.forEach((s: any) => {
                                if (s.name == _selectedPositions[0].subTeam[0].services[0].name) {
                                    if (_selectedPositions[0].subTeam[0].services[0].options.length > 1) {
                                        if (isAllChecked == false) {
                                            //console.log("is all unchecked",s.position)
                                            s.options.splice(0, s.options.length)
                                        }
                                        else {
                                            s.options.splice(0, s.options.length)
                                            _selectedPositions[0].subTeam[0].services[0].options.forEach((opt: any) => {
                                                s.options.push(opt)
                                                // console.log(s.options)
                                            })
                                        }
                                    }
                                    else {
                                        let isExists = s.options.filter((op: any) => op.name == _selectedPositions[0].subTeam[0].services[0].options[0].name).length > 0
                                        if (!isExists) {
                                            s.options.push(_selectedPositions[0].subTeam[0].services[0].options[0])
                                        }
                                        else {
                                            let index = s.options.findIndex((o: any) => o.name == _selectedPositions[0].subTeam[0].services[0].options[0].name);
                                            s.options.splice(index, 1)

                                        }
                                    }
                                }
                                else {
                                    let isServiceExists = st.services.filter((op: any) => op.name == _selectedPositions[0].subTeam[0].services[0].name).length > 0
                                    if (!isServiceExists) {
                                        st.services.push(_selectedPositions[0].subTeam[0].services[0])
                                    }
                                }

                            })

                        }
                        else {
                            let isSubTeamExists = i.subTeam.filter((op: any) => op.name == _selectedPositions[0].subTeam[0].name).length > 0
                            if (!isSubTeamExists) {
                                i.subTeam.push(_selectedPositions[0].subTeam[0])
                            }
                        }

                    })
                }
                else {
                    let isTeamExists = allSelectedPosition.filter((op: any) => op.name == _selectedPositions[0].name).length > 0
                    if (!isTeamExists) {
                        allSelectedPosition.push(_selectedPositions[0])
                    }
                }
            })
        }
        allSelectedPosition.forEach(i => {
            let optionsLength = 0;
            i.subTeam.forEach((j: any) => {
                j.services.forEach((s: any) => {
                    optionsLength += s.options.length
                })
                if (optionsLength == 0) {
                    let index = allSelectedPosition.findIndex(index => index.name == i.name)
                    allSelectedPosition.splice(index, 1)
                }
            })
        })
        this.setState({ selectedPositions: allSelectedPosition, disabledfooterpositionlength: this.state.selectedPositions.length })
        //console.log(this.state.selectedPositions)
    }
    checkSubmitNextButton = () => {
        if (this.state.contactRequest.fullName.length > 0 && this.state.contactRequest.mobile.length > 18 && this.state.contactRequest.email.includes("@")) {
            this.setState({ ChekSendButton: false })
        }
        else {
            this.setState({ ChekSendButton: true })
        }
    }
    async componentDidUpdate(prevProps: IAllServices, prevState: IAllServicesState) {
        if (prevProps.ItemName != this.props.ItemName) {
            let teams = [...this.state.teams];
            teams.forEach(i => {
                i.sub_team.forEach(j => {
                    j.services.forEach(s => {
                        s.options.forEach(o => [
                            o.isChecked = false
                        ])
                    })
                })
            })
            teams.forEach(i => {
                this.state.selectedPositions.forEach(j => {
                    if (i.name == j.name) {
                        i.sub_team.forEach(sti => {
                            j.subTeam.forEach((stj: any) => {
                                if (sti.name == stj.name) {
                                    sti.services.forEach(si => {
                                        stj.services.forEach((sj: any) => {
                                            if (si.name == sj.name) {
                                                si.options.forEach(oi => {
                                                    sj.options.forEach((oj: any) => {
                                                        if (oi.name == oj.name) {
                                                            oi.isChecked = true
                                                        }
                                                    })
                                                })
                                            }
                                        })
                                    })
                                }
                            })
                        })
                    }
                })
            })
            this.setState({ teams: teams })
            let sub_teamItem = this.state.teams.filter(i => i.name == this.props.ItemName)
            if (sub_teamItem.length != 0) {
                this.setState({ sub_team: sub_teamItem })
            }

        }
    }


    async componentWillReceiveProps(nextProps: IAllServices) {
        let teams: ITeam[] = [];
        let _allNames = nextProps.teams.map(i => i.name)
        this.setState({ teams: ((nextProps.teams == undefined) ? [] : nextProps.teams), allTeamName: _allNames });
    }

    public onChangeInput = (e: any, item: any) => {
        let value = '';
        if (e) {
            value = e.target.value;
        }
        else if (item) {
            value = item;
        }

        let allOptions: any[] = [];
        let options: string[] = []
        this.state.teams.map(i => {
            i.sub_team.map(j => {
                j.services.map((s: any) => {
                    s.options.map((o: { name: any; }) => {
                        allOptions.push(o.name)   //Search options
                        allOptions.push(s.name)   //Search service

                    })
                })
            })
        })
        allOptions.forEach(i => {
            if (i?.startsWith(value)) {
                options.push(i)
            }
        })
        if (e?.target?.value) {
            this.setState({ isOptionOpen: true })
        }
        else {
            this.setState({ isOptionOpen: false })
        }
        options = options.filter((v, i, a) => a.indexOf(v) === i);
        console.log("Arara", e)
        console.log("Ororo", item)
        console.log("Oraora", value)
        this.setState({ searchOptions: options, valueText: value })
    }
    getOptionsCountByTeam = (name: string) => {
        let countResult = 0;
        let selectedPositions = [...this.state.selectedPositions]
        selectedPositions = selectedPositions.filter(i => i.name == name);
        selectedPositions.forEach(i => {
            i.subTeam.forEach((j: any) => {
                j.services.forEach((s: any) => {
                    countResult += s.options.length
                })
            })
        })
        return countResult
    }
    handelChange = (key: string, value: any) => {
        // console.log('enableAreaCodes', value)
        let newRequest = { ...this.state.contactRequest };
        newRequest[key] = value;
        this.validate(newRequest)
        this.setState({ contactRequest: newRequest });
    }
    handleSubmit = (e: { preventDefault: () => void; }) => {
        var axios = require('axios');
        var data = JSON.stringify({
            "user": {
                "FullName": this.state.contactRequest.fullName,
                "mobile": this.state.contactRequest.mobile,
                "email": this.state.contactRequest.email
            },
            "teams": this.state.selectedPositions
        });

        var config = {
            method: 'post',
            url: '/api/AbonaServices/sendEmail',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response: { data: any; }) {
                //console.log(JSON.stringify(response.data));
            })
            .catch(function (error: any) {
                //console.log(error);
            });
        e.preventDefault()
        if (this.validate()) {
            this.setState({ fromVisible: false, IsSuccesses: true, FooterVisible: true, ResultVisible: false })
        }
    }

    handleClickOpen = () => {
        this.serviseresult()
        this.setState({ isFormOpen: true, ResultVisible: true })
    }
    Confirm = () => {
        this.setState({ fromVisible: true, ResultVisible: false })
    }
    handleClose = () => {
        this.setState({ isFormOpen: false, fromVisible: false, IsSuccesses: false, ResultVisible: false, DiscardChanges: false });
    };
    ResultWindowClose = () => {
        this.setState({ DiscardChanges: true, ResultVisible: false })
    }
    lasthandleClose = () => {
        this.setState({ LastCloseButton: true, fromVisible: false })
    }
    handleCloseButtonNo = () => {
        this.setState({ ResultVisible: true, DiscardChanges: false })
    }
    lasthandleCloseButtonNo = () => {
        this.setState({ fromVisible: true, LastCloseButton: false })
    }
    ResultWindowYESClose = async () => {
        let ClearTEams = this.props.teams || []
        for (let a = 0; a < ClearTEams.length; a++) {
            for (let j = 0; j < ClearTEams[0].sub_team[0].services.length; j++) {
                for (let i = 0; i < ClearTEams[a].sub_team[0].services[j]?.options.length; i++) {
                    if (ClearTEams[a].sub_team[0].services[j].options != undefined) {
                        if (ClearTEams[a].sub_team[0].services[j].options[i].isChecked != undefined) {
                            ClearTEams[a].sub_team[0].services[j].options[i].isChecked = false
                            ClearTEams[a].sub_team[0].services[j].isAllSelected = false
                        }
                    }
                }
            }
        }
        console.log('ClearTEams[a].sub_team[0].services[j].options[i]', ClearTEams[0].sub_team[0].services[0])
        await this.setState(
            {
                teams: ((ClearTEams == undefined) ? [] : ClearTEams),
                selectedPositions: [],
                disabledfooterpositionlength: 0,
                isOptionOpen: false,
                isFormOpen: false,
                fromVisible: false,
                IsSuccesses: false,
                FooterVisible: true,
                ChekSendButton: true,
                closeIcon: false,
                ResultVisible: false,
                ShowHide: false,
                DiscardChanges: false,
                VariableToPassStateUpdate: true,
                LastCloseButton: false
            }
        )
        setTimeout(() => { this.setState({ VariableToPassStateUpdate: false }) }, 100)
    }
    serviseresult = () => {
        console.log("TEAMS", this.state.teams);

        var ResultItems: any[] = []
        for (let a = 0; a < this.state.teams.length; a++) {
            for (let d = 0; d < this.state.teams[a].sub_team.length; d++) {
                for (let j = 0; j < this.state.teams[a].sub_team[d].services.length; j++) {
                    for (let i = 0; i < this.state.teams[a].sub_team[d].services[j].options.length; i++) {
                        if (this.state.teams[a].name == "Service Desk (L1, L2)") {

                            console.log("this.state.teams", this.state.teams[a])
                            console.log("this.state.teams", this.state.teams[a].sub_team[d])
                        }
                        if (this.state.teams[a]?.sub_team[d]?.services[j]?.options[i]?.isChecked == true) {
                            ResultItems.push({
                                h1: this.state.teams[a].name,
                                h2: this.state.teams[a].sub_team[d].services[j].name,
                                h3: this.state.teams[a].sub_team[d].services[j].options[i].name
                            })
                        }
                    }
                }
            }
        }
        console.log("ResultItems", ResultItems)
        var ParseResult: any = {}
        ResultItems.forEach(i => {
            if (ParseResult[i["h1"]] == undefined) {
                ParseResult[i["h1"]] = {};
            }
            if (ParseResult[i["h1"]][i["h2"]] == undefined) {
                ParseResult[i["h1"]][i["h2"]] = [];
            }
            ParseResult[i["h1"]][i["h2"]].push(i["h3"]);
        })
        let OpenOptions = (id: any) => {
            let ListOptions: any = document.getElementById(id);
            let CheckMark: any = document.getElementById(id + 'checkmark');
            if (ListOptions.style.display == 'none') {
                ListOptions.style.display = 'block'
                CheckMark.style.transform = 'rotate(0deg)'
            }
            else {
                ListOptions.style.display = 'none'
                CheckMark.style.transform = 'rotate(180deg)'
            }
            //console.log('e', id)
        }
        let Res = (TeamName: any) => {
            console.log("TETETETETETET", TeamName)
            return (<>
                <div className="Teams"><h2 className="Teamstitle">{TeamName.toUpperCase()}</h2></div>
                <ul className="Serviceslist">
                    <li className="Services">
                        {Object.keys(ParseResult[TeamName]).map(function (ServiceName) {
                            return (
                                <ul style={{ padding: 0 }}>
                                    <li className="Servicesul" onClick={() => OpenOptions(TeamName + ServiceName)}>{ServiceName.substring(0, 1).toUpperCase() + ServiceName.substring(1)}
                                        <span>
                                            <img id={TeamName + ServiceName + 'checkmark'} style={{ marginLeft: "15px", transform: "rotate(180deg)" }} src={require("../img/Stroke.png")} />
                                        </span>
                                    </li>
                                    <ul id={TeamName + ServiceName} style={{ display: "none" }}>
                                        {ParseResult[TeamName][ServiceName].map((options: any) => (
                                            <li className="optionslist">{options.substring(0, 1).toUpperCase() + options.substring(1)}</li>
                                        ))}
                                    </ul>
                                </ul>)
                        })}
                    </li>
                </ul>
            </>);
        }
        return (<div>
            {Object.keys(ParseResult).map(function (key) {
                return Res(key)
            })}
        </div>)
    }
    public Subtitle = "";
    public ChoiseVisibleOptions = (a: string) => {
        this.Subtitle = a
        if (this.state.ShowHide) {
            this.setState({ ShowHide: false })
        } else {
            this.setState({ ShowHide: true })
        }
        //console.log(this.state.ShowHide)
    }
    public searchTextOnPage = (name: any) => {
        OpenAfterSearch = true
        if (typeof name === 'string' || name instanceof String) {
        }
        else {
            name = this.state.valueText;
        }
        console.log("ItemsName : ", this.state.teams);
        let sub_teamItem = this.state.teams.filter(i => i.sub_team.some(j => j.services.some(s => s.options.some(o => o.name == name || s.name == name))))
        if (sub_teamItem.length != 0) {
            let items = document.querySelectorAll("a");
            items.forEach(i => {
                if (i != null) {
                    if (i.className == 'active') {
                        i.className = 'link'
                    }
                }
            })

            var element = document.getElementById(sub_teamItem[0].name)
            if (element != null) {
                element.className = 'active'
            }
            this.setState({ sub_team: [sub_teamItem[0]] })

            this.props.hideSearchElementsFunc();
            // window.scrollTo(0, 900)
            // this.props.MainPageChange(sub_teamItem[0].name)
        }
    }
    handleClickShow = () => {
        if (this.state.FooterVisible) {
            this.setState({ FooterVisible: false })
            this.setState({ disabledfooterpositionlength: this.state.selectedPositions.length })
        }
    }
    closeIcon = () => {
        if (!this.state.FooterVisible) {
            this.setState({ FooterVisible: true, disabledfooterpositionlength: 0 })
        }
    }
    render() {
        if (this.props.ItemName != null) {
            OpenAfterSearch = true
        }
        if (this.props.ItemName == null) {
            OpenAfterSearch = false
        }
        return (<>
            {OpenAfterSearch &&
                <div className="createslacontractdiv">
                    <button className="createslacontractdivbutton" onClick={this.handleClickShow}>CREATE SLA CONTRACT</button>
                </div>
            }
            <div id={"searchBar"} style={{ visibility: this.props.showSearchButtonDiv ? "visible" : "hidden" }} className={this.props.showSearchButtonDiv == false ? "SearchBarByDefault" : "SearchBar"}>
                <form action="" className="search" autoComplete="off">
                    {
                        this.state.valueText.length == 0 &&
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ margin: "10px" }}>
                            <path className='SvgSearch' d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.132 12.867 2 9 2C5.132 2 2 5.132 2 9C2 12.867 5.132 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875Z" fill={'#333333'} />
                        </svg>
                    }
                    <input type="text" id="input-search" className="input-search" value={this.state.valueText} onChange={(e) => { this.onChangeInput(e, null) }} />
                    <div className="btn-search" onClick={this.searchTextOnPage}>
                        <span className="btn-text" >Search</span>
                    </div>
                </form>
                {this.state.isOptionOpen ? <section style={{ position: "absolute", zIndex: "2" }} className="section-options-items">
                    {this.state.searchOptions.map((item: string) => {
                        return <option className="item-option" value={item} onClick={() => this.onChangeInput(null, item)}>{item}</option>
                    })}
                </section> : <></>}
            </div>


            {OpenAfterSearch &&
                this.state.sub_team.map(i => {
                    return (
                        <Team hideSearchElements={this.props.hideSearchElements} hideSearchElementsFunc={this.props.hideSearchElementsFunc} searchTextOnPage={this.searchTextOnPage} showSearchButton={this.props.showSearchButton} searchResult={this.state.searchOptions} showSearchButtonDiv={this.props.showSearchButtonDiv} VariableToPassStateUpdate={this.state.VariableToPassStateUpdate} getSelectedPositions={this.getSelectedPositions} allNames={this.state.allTeamName} TeamName={this.props.ItemName} value={this.state.valueText} name={i.name} sub_team={i.sub_team} checkVisible={this.state.FooterVisible}></Team>)
                })}
            {this.state.selectedPositions.length > 0 &&
                <div className="footer">
                    <div className="footerList">
                        <span className="close" onClick={this.ResultWindowYESClose}></span>
                        {this.state.allTeamName.map((i, key) => {
                            console.log("allTeamName", i)
                            let render = [];
                            if (key == 7) {
                                render.push(<span style={{ height: "25px", fontSize: "12px", marginBottom: "30px" }}>{i.substring(0, 1).toUpperCase() + i.substring(1)}: <span className="boldfooterspan">{this.getOptionsCountByTeam(i)}</span></span>)
                            }
                            else {
                                render.push(<span style={{ height: "25px", fontSize: "12px", marginBottom: "1px" }}>{i.substring(0, 1).toUpperCase() + i.substring(1)}: <span className="boldfooterspan">{this.getOptionsCountByTeam(i)}</span></span>)
                            }
                            return (
                                render
                            )
                        })}
                    </div>
                    <div>
                        <button className="nextButton" onClick={this.handleClickOpen} disabled={this.state.selectedPositions.length == 0}>NEXT</button>
                    </div>
                </div>}
            <Dialog
                open={this.state.isFormOpen}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xl"
            >
                {this.state.ResultVisible &&
                    <><BootstrapDialogTitle onClose={this.ResultWindowClose} id={""}>
                        <h2 className="ResultTitle">Result</h2>
                    </BootstrapDialogTitle><DialogContent className="DialogContentContainer">
                            <DialogContentText id="alert-dialog-description-result">
                                <div style={{ height: "100%", display: "flex", justifyContent: "center", }}>
                                    <div id="resultpush" className="ResultTeams">
                                        {this.serviseresult()}
                                    </div>
                                </div>
                                <div className="FooterPopUp">
                                    <button className="goback" onClick={this.handleClose} disabled={this.state.selectedPositions.length == 0}>GO BACK</button>
                                    <button
                                        className="CONFIRM"
                                        onClick={this.Confirm}
                                    >CONFIRM</button>

                                </div>
                            </DialogContentText>
                        </DialogContent></>}

                {this.state.fromVisible
                    &&
                    <><BootstrapDialogTitle onClose={this.lasthandleClose} id={""}>
                        <h2 className="LeaveUour">Leave your Contacts</h2>
                    </BootstrapDialogTitle><DialogContent className="FormSendWindow">
                            <DialogContentText id="alert-dialog-description">
                                <div className='FormPopUp'>
                                    <Form className="formDiv" onSubmit={this.handleSubmit}>
                                        <div className="field">
                                            <label htmlFor="">Name Surname</label>
                                            <TextField
                                                size='small'
                                                className="FormInputs"
                                                error={this.state.errors.fullName}
                                                id="outlined-error"
                                                value={this.state.contactRequest.fullName}
                                                onChange={(e) => { this.handelChange("fullName", e.currentTarget.value); }} />
                                        </div>
                                        <div className="field">
                                            <label style={{ width: "300px" }} htmlFor="">Phone number</label>
                                            <div className="TelephoneInputs">
                                                <ReactPhoneInput
                                                    countryCodeEditable={false}
                                                    enableAreaCodes={false}
                                                    placeholder='+123'
                                                    inputStyle={{ width: "100px", height: '40px' }}
                                                    country={"ua"}
                                                    //value={this.state.contactRequest.mobile}
                                                    onChange={(e: { valueOf: () => any; }) => { this.handelChange("mobile", e.valueOf()); }}
                                                />
                                                <div className="InputPhoneContainer">
                                                    <ReactPhoneInput

                                                        inputStyle={{
                                                            marginLeft: "47px", height: '40px', width: '275px', borderBottom: borderproperties, borderTop: borderproperties, borderLeft: borderSight,
                                                            borderRight: borderSight
                                                        }}
                                                        placeholder='+123(45) 678 91 01'
                                                        countryCodeEditable={false}
                                                        buttonStyle={{ visibility: "hidden", }}
                                                        country={"ua"}
                                                        value={this.state.contactRequest.mobile}
                                                        onChange={(e: { valueOf: () => any; }) => { this.handelChange("mobile", e.valueOf()); }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="field">
                                            <label htmlFor="">Phone number</label>
                                            <MuiPhoneNumber
                                                size='small'
                                                className="FormInputs"
                                                error={this.state.errors.mobile}
                                                id="outlined-error"
                                                value={this.state.contactRequest.mobile}
                                                defaultCountry={'ua'}
                                                variant="outlined"
                                                onChange={(e) => { this.handelChange("mobile", e.valueOf()); }} />
                                        </div> */}
                                        <div className="field">
                                            <label htmlFor="">Email</label>
                                            <TextField
                                                size='small'
                                                className="FormInputs"
                                                error={this.state.errors.email}
                                                id="outlined-error"
                                                value={this.state.contactRequest.email}
                                                onChange={(e) => { this.handelChange("email", e.currentTarget.value); }} />
                                        </div>

                                        <div className="FooterPopUpForm">
                                            <p className="ParagraphFooter">We will send you copy of PDF file to your e-mail</p>
                                            <button
                                                disabled={this.state.ChekSendButton}
                                                //disabled={!Object.values(this.state.errors).every(x => x == "").valueOf()}
                                                className="submitButton"
                                                type="submit"
                                            >SEND</button>
                                        </div>
                                    </Form>
                                </div>
                            </DialogContentText>
                        </DialogContent></>
                }
                {
                    this.state.IsSuccesses &&
                    <><BootstrapDialogTitle onClose={this.handleClose} id={""}>
                    </BootstrapDialogTitle><DialogContent style={{ height: "250px", width: "525px" }}>
                            <DialogContentText id="alert-dialog-description">
                                <div style={{ height: "100%", marginLeft: "100px", marginRight: "100px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                    <img style={{ marginTop: "27px", width: "60px", height: "60px" }} src={require('../img/checkIcone.png')} />
                                    <h2>Thank you!</h2>
                                    <p style={{ textAlign: "center", width: "400px" }}>Your SLA contract application was successfully send.<br />We will contact you soon!</p>
                                </div>
                            </DialogContentText>
                        </DialogContent></>
                }
                {
                    this.state.DiscardChanges &&
                    <><BootstrapDialogTitle onClose={this.handleClose} id={""}>
                    </BootstrapDialogTitle><DialogContent style={{ height: "250px", width: "515px" }}>
                            <DialogContentText id="alert-dialog-description">
                                <div className='DiscardChangesWindow'>
                                    <img src={require('../img/Attention.png')} />
                                    <p>Are you sure you want to disgard changes?</p>
                                </div>
                                <div className="FooterPopUpDiscard">
                                    <button className="buttonNo" onClick={this.handleCloseButtonNo}>NO</button>
                                    <button
                                        className="buttonYes"
                                        onClick={() => { this.ResultWindowYESClose() }}
                                    >YES</button>

                                </div>
                            </DialogContentText>
                        </DialogContent></>
                }
                {
                    this.state.LastCloseButton &&
                    <><BootstrapDialogTitle onClose={this.handleClose} id={""}>
                    </BootstrapDialogTitle><DialogContent style={{ height: "250px", width: "515px" }}>
                            <DialogContentText id="alert-dialog-description">
                                <div className='DiscardChangesWindow'>
                                    <img src={require('../img/Attention.png')} />
                                    <p>Are you sure you want to disgard changes?</p>
                                </div>
                                <div className="FooterPopUpDiscard">
                                    <button className="buttonNo" onClick={this.lasthandleCloseButtonNo}>NO</button>
                                    <button
                                        className="buttonYes"
                                        onClick={() => { this.ResultWindowYESClose() }}
                                    >YES</button>

                                </div>
                            </DialogContentText>
                        </DialogContent></>
                }
                <DialogActions>

                </DialogActions>
            </Dialog >
        </>)
    }
}
export default AllServices;


function mOut() {
    throw new Error("Function not implemented.");
}